<template>
    <create-new-category
        type="Role"
        :field_type="'role'"
        :error-text="errorText"
        @on-save-btn-click="submit($event)"
        @input="resetError"
    />
</template>

<script>
import CreateNewCategory from '@/components/create-category/CreateNewCategory';
import categoryErrorMixin from '@/mixins/content/categoryErrorMixin';

import {
    ACTION_CREATE_ROLE_POST
} from '@/store/modules/permissions/permissions-edit/action-types'
import { createNamespacedHelpers } from 'vuex';

const {
    mapActions: mapPermissionEditActions
} = createNamespacedHelpers('permissionsEdit');

export default {
    name: 'CreateRolePage',
    components: { CreateNewCategory },
    mixins: [categoryErrorMixin],
    methods: {
        ...mapPermissionEditActions([
            ACTION_CREATE_ROLE_POST
        ]),
        async submit({ name }) {
            try {
                const response = await this[ACTION_CREATE_ROLE_POST]({ name })
                const errors = response?.response?.data?.errors
                if (!errors && !response.message) {
                    this.$bus.$emit('showSnackBar', 'Your role was created', 'success')
                    await this.$router.push({ name: 'posts-list-roles' })
                }
                if (errors) {
                    // eslint-disable-next-line prefer-destructuring
                    this.errorText = errors.name[0]
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
}
</script>
